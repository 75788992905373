import React from 'react'
import Helmet from 'react-helmet'

import Layout from "../../components/layout"
import BgImage from '../../components/bg-image'

const Features = () => (
  <Layout>
    <Helmet title="Pool Interiors" />
    <BgImage image="/assets/pool-surrounds.jpg" className="top">
      <h1>Water Features and Feature Walls</h1>
    </BgImage>
    <section className="content">
      <p>
        Adding a feature such as a waterfall, or feature wall, is one of the
        best ways to make your pool even more attractive and adds value to your
        home in the process. We specialize in the following features, but are
        more than happy to help you achieve your dream pool experience.
      </p>
      <ul>
        <li>Custom Water Features</li>
        <li>Features Walls / Cultured Stone Walls</li>
        <li>Equipment for Water Features</li>
      </ul>
    </section>
  </Layout>
)

export default Features
